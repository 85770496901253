import './ProfilePage.css';
import Header from '../components/Header';

function ProfilePage() {
  return (
    <div className="ProfilePage">
      <Header/>

      <div className="profile-page-content">
        Profile Page
      </div>
    </div>
  );
}

export default ProfilePage;
