const disableScrolling = () => {
  document.body.style.overflow = 'hidden';
}

const enableScrolling = () => {
  document.body.style.overflow = 'unset';
}

export {
  disableScrolling,
  enableScrolling
};
