import { useNavigate } from 'react-router-dom';
import { isLoggedIn } from '../lib/session';
import { useEffect } from 'react';

function PrivateRoute({ children }) {
  const navigate = useNavigate();
  const loggedIn = isLoggedIn();

  useEffect(() => {
    if (!loggedIn) {
      navigate('/inicio-de-sesion');
    }
  }, [loggedIn]);
  
  return (
    <>{loggedIn && children}</>
  );
}

export default PrivateRoute;
