import './CreateTripPage.css';
import Header from '../components/Header';
import { useState } from 'react';
import SearchTools from '../components/SearchTools';
import { getToken } from '../lib/session';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from  "react-datepicker";
import { es } from 'date-fns/locale/es';
import { ReactComponent as CloseIcon } from '../icons/clear-input.svg';
import { useNavigate } from 'react-router-dom';

registerLocale('es', es);

function CreateTripPage() {
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    group: 'courier',
    origin_country: null,
    origin_city: null,
    destination_country: null,
    destination_city: null
  });

  const handleOnFilterChange = (value) => {
    setFilters(prev => ({ ...prev, ...value}));
  }

  const [date, setDate] = useState(null);
  const [description, setDescription] = useState('');

  const handleOnCreateTrip = (e) => {
    e.preventDefault();

    const formatDate = (date) => {
      if (date === null) {
        return null;
      }

      let year = date.getFullYear();
      let month = String(date.getMonth() + 1).padStart(2, '0'); // months are zero-based
      let day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const params = {
      trip: {
        group: filters.group === 'courier' ? 'sender' : 'courier',
        date: formatDate(date),
        origin_country: filters.origin_country,
        origin_city: filters.origin_city,
        destination_country: filters.destination_country,
        destination_city: filters.destination_city,
        description: description
      }
    };

    axios.post(`/api/trips/`, params, { headers: { 'Authorization': `Bearer ${getToken()}`} })
      .then((response) => {
        // console.log(response);
        // toast.success('Viaje creado con éxito.', { id: 'trip-created' });
        navigate('/mis-viajes');
      })
      .catch((error) => {
        console.log(error);
        toast.error('Ocurrió un error mientras se intentaba crear el viaje.', { id: 'error' });
      });
  };

  const disableSubmitButton = filters.origin_country === null || filters.destination_country === null || description === '';

  return (
    <div className="CreateTripPage"> 
      <Header/>

      <div className="create-trip-page-content">
        <Toaster position="top-center" reverseOrder={false}/>

        <div className="create-trip-page-title">Crear viaje</div>

        <div className="create-trip-page-search-tools">
            <SearchTools filters={filters} onFilterChange={handleOnFilterChange} hideOptionAll={true}/>
        </div>
        
        <div className="create-trip-page-date-wrapper">
          <span className="create-trip-page-date-label">FECHA DE SALIDA</span>

          <DatePicker
            calendarIconClassName="create-trip-page-date-icon"
            locale="es"
            placeholderText="Seleccionar"
            className="create-trip-page-date"
            minDate={new Date()}
            selected={date}
            onChange={setDate}/>

          {date !== null && <CloseIcon className="create-trip-page-date-close-icon" onClick={() => setDate(null)}/> }
        </div>

        <textarea
          className="create-trip-page-description"
          value={description}
          onChange={e => setDescription(e.target.value)}
          placeholder="Descripción..."
          maxLength={1000}
          rows={description.split("\n").length + 1}
          />

        <div className="create-trip-description-counter">{description.length}/1000</div>

        <button
          className={`create-trip-page-submit-button ${disableSubmitButton ? 'create-trip-page-submit-button-disabled' : ''}`}
          type="submit"
          disabled={disableSubmitButton}
          onClick={handleOnCreateTrip}>
            Crear viaje
        </button>
        
      </div>
    </div>
  );
}

export default CreateTripPage;


