import './App.css';
// import 'typeface-inter';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import SearchPage from './pages/SearchPage';
import SignUpPage from './pages/SignUpPage';
import SignInPage from './pages/SignInPage';
import ProfilePage from './pages/ProfilePage';
import AlertsPage from './pages/AlertsPage';
import AboutPage from './pages/AboutPage';
import VerifyEmailPage from './pages/VerifyEmailPage';
import GenerateFacebookTripJsonPage from './pages/GenerateFacebookTripJsonPage';
import CreateTripPage from './pages/CreateTripPage';
import MyTripsPage from './pages/MyTripsPage';

function App() {
    const router = createBrowserRouter([
      { path: '/', element: <SearchPage/> },
      { path: '/inicio-de-sesion', element: <SignInPage/> },
      { path: '/creacion-de-cuenta', element: <SignUpPage/> },
      { path: '/alertas', element: <PrivateRoute><AlertsPage/></PrivateRoute>},
      { path: '/perfil', element: <PrivateRoute><ProfilePage/></PrivateRoute>},
      { path: '/mis-viajes', element: <PrivateRoute><MyTripsPage/></PrivateRoute> },
      { path: '/crear-viaje', element: <PrivateRoute><CreateTripPage/></PrivateRoute> },
      { path: '/quienes-somos', element: <AboutPage/>},
      // { path: '/faqs', element: <FAQsPage/>},
      { path: '/verificar-correo-electronico', element: <VerifyEmailPage/>},
      { path: '/generate-trip', element: <GenerateFacebookTripJsonPage/>}
    ]);

  return (
    <div className="App" translate="no">
      <RouterProvider router={router} />
    </div>
        
  );
}

export default App;
