import './Input.css';
import { useState } from 'react';
import { ReactComponent as EmailIcon } from '../icons/email.svg';
import { ReactComponent as PasswordIcon } from '../icons/password.svg';
import { ReactComponent as EyeOpenIcon } from '../icons/eye-open.svg';
import { ReactComponent as EyeClosedIcon } from '../icons/eye-closed.svg';
import { ReactComponent as UserIcon} from '../icons/user.svg';

function Input({ type, value, placeholder, onChange }) {
  const [showPassword, setShowPassword] = useState(false);
  const isPassword = type === 'password';
  const internalType = function() {
    if (type === 'email') {
      return type;
    }

    if (isPassword) {
      return showPassword ? 'text' : 'password';
    }

    if (type === 'name') {
      return 'text';
    }
  }();

  const icon = {
    email: <EmailIcon className="input-email-icon"/>,
    password: <PasswordIcon className="input-password-icon"/>,
    name: <UserIcon className="input-user-icon"/>
  }[type];
  
  return (
    <div className="Input">
      {icon}

      <input
        className={`input-input ${isPassword ? 'input-input-password' : ''}`}
        type={internalType}
        placeholder={placeholder}
        value={value}
        onChange={onChange}/>

      {isPassword && <span className="input-eye-container" onClick={() => setShowPassword(prev => !prev)}>
        {showPassword ? <EyeOpenIcon className="input-eye-open-icon"/> : <EyeClosedIcon className="input-eye-closed-icon"/>}
      </span>}
    </div>
  );
}

export default Input;
