import './TripItem.css';
import { Link } from 'react-router-dom';
import { ReactComponent as PackageIcon } from '../icons/package-in-circle.svg';
import { ReactComponent as AirplaneIcon } from '../icons/airplane-in-circle.svg';
import { ReactComponent as AirplaneTakeOffIcon } from '../icons/airplane-take-off.svg';
import { ReactComponent as TrashCanIcon} from '../icons/trash-can.svg';
import { useState } from 'react';
import { trackTripClickedEvent } from '../lib/analytics';

function TripItem({ trip, onDelete }) {
  let {
    user, group, date, origin_country, origin_city, destination_country, destination_city, description, created_at
  } = trip;

  const shortenedDescription = function() {
    const lines = description.split("\n");
    const length = description.length;

    if (lines.length > 6) {
      return lines.slice(0, 6).join("\n");
    }

    if (length > 300) {
      return description.slice(0, 300);
    }

    return description;
  }();

  const [showShortenedDescription, setShowShortenedDescription] = useState(description !== shortenedDescription);

  const origin = [origin_city, origin_country].filter((el => el)).join(', ');
  const destination = [destination_city, destination_country].filter((el => el)).join(', ');

  const timestampFormattingOptions = { year: 'numeric', month: 'short', day: 'numeric' };
  const creationDate = new Date(created_at).toLocaleDateString('es', timestampFormattingOptions);

  const dateFormattingOptions = { ...timestampFormattingOptions, timeZone: 'UTC' };
  const tripDate = new Date(date).toLocaleDateString('es', dateFormattingOptions);

  const handleOnClick = () => {
    trackTripClickedEvent();
  }

  return (
    <div className="TripItem">
        <div className="trip-item-header">
          {group === 'sender' && <PackageIcon className="package-icon"/>}
          {group === 'courier' && <AirplaneIcon className="airplane-icon"/>}

          <div className="trip-item-header-content">
            <span className="trip-item-header-content-group-name">Por {user}</span>
            <span className="trip-item-header-content-author">El {creationDate}</span>
          </div>

          {onDelete &&
            <div className="trip-item-trash-can-icon-wrapper">
              <TrashCanIcon className="trip-item-trash-can-icon" onClick={() => onDelete(trip.id)} />
            </div>
          }
        </div>

        <Link className="trip-item-name" target="_blank" rel="noopener noreferrer" onClick={handleOnClick}>
          {origin} → {destination}
        </Link>

        {date &&
          <div className="trip-item-date">
            <AirplaneTakeOffIcon className="airplane-take-off-icon"/>
            {tripDate}
          </div>
        }

        <div>
          {
            showShortenedDescription ?
              <TripItemShortenedDescription shortenedDescription={shortenedDescription} onClick={() => setShowShortenedDescription(false)}/>
              :
              <TripItemDescription description={description}/>
          }
        </div>
    </div>
  );
}
   
export default TripItem;

// The following components were created to overcome Google translation issues on mobile.
// Google translation modifies the DOM. In some cases it respects the directive translate="no", but not always.
// Once the DOM was modified, clicking "Ver más" resulted in an error of "The object can not be found here".

// The original code was using the ternary operator
// {
//   showShortenedDescription ?
//   <div>{shortenedDescription}... <div onClick={() => setShowShortenedDescription(false)}>Ver más</div></div>
//   :
//   <div>{description}</div>
// }

// We created the following components and the error went away.

function TripItemShortenedDescription({ shortenedDescription, onClick }) {
  return (
    <div className="trip-item-description" >
      {shortenedDescription}
      ...
      <div className="trip-item-show-more" onClick={onClick}>
        Ver más
      </div>
    </div>
  );
}

function TripItemDescription({ description, onClick }) {
  return (
    <div className="trip-item-description" >
      {description}
    </div>
  );
}
