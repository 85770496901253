import './AlertItem.css';
import { ReactComponent as TrashCanIcon} from '../icons/trash-can.svg';

function AlertItem({ alert, onDelete }) {
  const groupMessage = alert.group === 'sender' ? 'Buscando remitente' : 'Buscando viajero';
  const origin = [alert.origin_city, alert.origin_country].filter((el => el)).join(', ');
  const destination = [alert.destination_city, alert.destination_country].filter((el => el)).join(', ');

  return (
    <div className="AlertItem">

      <div className="alert-item-content">
        <div className="alert-item-">
        <div className="alert-item-trip-name">
            {origin} → {destination}
          </div>

          <div className="alert-item-group-message">
            {groupMessage}
          </div>
        </div>

        <TrashCanIcon className="alert-item-trash-can-icon" onClick={() => onDelete(alert.id)} />
      </div>
    </div>
  );
}
   
export default AlertItem;
