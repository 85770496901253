import './AlertList.css';
import AlertItem from './AlertItem';

function AlertList({ alerts, onDelete }) {

  if (alerts === null) {
    return <></>;
  }

  if (alerts.length === 0) {
    return <p style={{color: 'var(--text-secondary-color)'}}>En este momento no tienes alertas guardadas.</p>;
  }

  return (
    <div className="AlertList">
      {alerts.map(alert => <AlertItem alert={alert} onDelete={onDelete} key={alert.id}/>)}
    </div>
  );
}
   
export default AlertList;
