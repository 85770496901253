import * as amplitude from '@amplitude/analytics-browser';

const isProduction = () => {
  return process.env.NODE_ENV === 'production';
}

const isAdmin = () => {
  const key = 'IS_ADMIN';
  return localStorage.getItem(key) === 'true';
}

const canTrackEvent = () => {
  return isProduction() && !isAdmin();
}

const getAmplitudeApiKey = () => {
  return process.env.REACT_APP_AMPLITUDE_API_KEY;
}

const initAmplitude = () => {
  if (canTrackEvent()) {
    amplitude.init(getAmplitudeApiKey(), { 'autocapture': true });
  }
}

const trackTripClickedEvent = () => {
  if (canTrackEvent()) {
    amplitude.track('TripClicked');
  }
}

const trackTripFilterChangedEvent = (filter) => {
  if (canTrackEvent()) {
    amplitude.track('TripFilterChanged', filter);
  }
}

const trackNextPageLoadedEvent = () => {
  if (canTrackEvent()) {
    amplitude.track('NextPageLoaded');
  }
}

const trackMenuButtonClickedEvent = () => {
  if (canTrackEvent()) {
    amplitude.track('MenuButtonClicked');
  }
}

export {
  initAmplitude,
  trackTripClickedEvent,
  trackTripFilterChangedEvent,
  trackNextPageLoadedEvent,
  trackMenuButtonClickedEvent
};
