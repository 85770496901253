import './ScrollToTopButton.css';
import ScrollToTop from 'react-scroll-to-top';
import { ReactComponent as UpArrowIcon } from '../icons/up-arrow.svg';

function ScrollToTopButton() {
  return (
     <ScrollToTop smooth className="ScrollToTopButton" component={<UpArrowIcon className="up-arrow-icon"/>}/>
  );
}

export default ScrollToTopButton;
