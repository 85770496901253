import './EnableDisableAlertsButton.css';
import { ReactComponent as BellAddIcon} from '../icons/bell-add.svg';
import { ReactComponent as BellRemoveIcon} from '../icons/bell-remove.svg';
import { ReactComponent as BellCancelIcon} from '../icons/bell-cancel.svg';

function EnableDisableAlertsButton({ isEnabled, onEnableAlerts, onDisableAlerts, numberOfAlerts, allowClickEnableDisableAlertsButton}) {
   const icon = function() {
      if (!allowClickEnableDisableAlertsButton) {
         return <BellCancelIcon className="enable-disable-alerts-button-bell-cancel-icon"/>;
      }

      if (isEnabled) {
         return <BellRemoveIcon className="enable-disable-alerts-button-bell-remove-icon"/>;
      }

      return <BellAddIcon className="enable-disable-alerts-button-bell-add-icon"/>;
   }();

   const handleOnClick = function() {
      if (!allowClickEnableDisableAlertsButton) {
         return () => {};
      }

      if (isEnabled) {
         return onDisableAlerts;
      }

      return onEnableAlerts;
   }();

   return (
      <div
        className={`EnableDisableAlertsButton ${allowClickEnableDisableAlertsButton ? '' : 'enable-disable-alerts-button-disabled'}`}
        onClick={handleOnClick}
        disabled={!allowClickEnableDisableAlertsButton}>
         {icon}

         {numberOfAlerts !== null &&
            <div className="enable-disable-alerts-button-counter-container">
               {[...Array(numberOfAlerts)].map((e, i) => <span className="enable-disable-alerts-button-counter-dot" key={i}/>) }
            </div>
         }
      </div>
   );
}

export default EnableDisableAlertsButton;
