import './SignUpPage.css';
import { useState } from 'react';
import axios from 'axios';
import validator from 'validator';
import capitalize from 'capitalize';
import Input from '../components/Input';
import Header from '../components/Header';

function SignUpPage() {
  const [signUpResult, setSignUpResult] = useState('pending');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState([]);

  const handleOnSignUp = (e) => {
    e.preventDefault();
    const errors = [];

    if (name.length < 3) { errors.push('El nombre debe tener al menos 3 caracteres.'); }
    if (!validator.isEmail(email)) { errors.push('El correo electrónico no tiene el formato correcto. Por favor, revísalo.'); }
    if (password.length < 8) { errors.push('La contraseña debe tener al menos 8 caracteres.'); }

    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    const params = { user: { name: name, email: email, password: password } };

    axios.post('/api/users', params)
      .then((response) => {
        setSignUpResult('success');
      })
      .catch((error) => {
        console.log(error);
        setSignUpResult('failed');
      });
  }

  const disableSubmitButton = name === '' || email === '' || password === '';

  return (
    <div className="SignUpPage">
      <Header/>

      <div className="sign-up-page-content">
        {/* <div className="sign-up-page-logo-container">
          <span className="sign-up-page-logo"></span>
        </div> */}

        <div className="sign-up-page-title">Creación de cuenta</div>

        {signUpResult === 'success' && <SignUpPageSuccessful email={email}/>}
        {signUpResult === 'failed' && <SignUpPageFailed/>}

        {signUpResult === 'pending' && <>

          {errors.length > 0 &&
            <div className="sign-up-page-errors-container">
              {errors.map((error, index) => <div className="sign-up-page-error" key={index}> {error}</div>)}
            </div>
          }

          <div className="sign-up-page-form">
              <form noValidate onSubmit={handleOnSignUp}>
                <div className="sign-up-page-input-container">
                  <Input type="name" placeholder="Nombre" value={name} onChange={(e) => setName(capitalize.words(e.target.value))}/>
                </div>

                <div className="sign-up-page-input-container">
                  <Input type="email" placeholder="Correo electrónico" value={email} onChange={(e) => setEmail(e.target.value)}/>
                </div>

                <div className="sign-up-page-input-container">
                  <Input type="password" placeholder="Contraseña" value={password} onChange={(e) => setPassword(e.target.value)}/>
                </div>

                <button className={`sign-up-page-submit-button ${disableSubmitButton ? 'sign-up-page-submit-button-disabled' : ''}`} type="submit" disabled={disableSubmitButton}>Crear cuenta</button>
              </form>
          </div>

        </>}
      </div>
    </div>
  );
}

export default SignUpPage;

function SignUpPageSuccessful({ email }) {
  return (
    <div className="SignUpPageSuccessful">
      Enviamos un correo electrónico a la dirección <span className="sign-up-page-successful-email">{email}</span> con los siguientes pasos para completar la creación de tu cuenta.
    </div>
  )
}

function SignUpPageFailed() {
  return (
    <div className="SignUpPageFailed">
      Ocurrió un error al crear tu cuenta. Por favor, intenta otra vez.
    </div>
  )
}
