import './Select.css';
import SelectComponent from 'react-select';
import { createFilter } from 'react-select';

function Select({ label, defaultOption, options, onChange }) {
  const filterConfig = { stringify: (option => option.label) }

  return (
    <div className="Select">
      <span className="select-label">{label}</span>

      <SelectComponent
        defaultValue={defaultOption}
        key={defaultOption}
        placeholder="Seleccionar"
        isClearable={true}
        options={options}
        filterOption={createFilter(filterConfig)}
        onChange={onChange}
        noOptionsMessage={() => 'No existen coincidencias'}
        classNames={{
          container: () => 'select-container',
          control: () => 'select-control',
          input: () => 'select-input',
          placeholder: () => 'select-placeholder',
          singleValue: () => 'select-single-value',
          menu: () => 'select-menu',
        }}/>
    </div>
  );
}

export default Select;
