// create origin options
export const createOriginOptions = (countries) => {
  return countries.flatMap(country => {
    const countryOption =  {
      value: { origin_country: country.code, origin_city: null }, label: country.name
    };
  
    const cityOptions = country.cities.map(city => {
      return {
        value: { origin_country: country.code, origin_city: city.code }, label: `${city.name}, ${country.name}`
      };
    });
  
    return [countryOption, ...cityOptions];
  }).sort(originOptionCompareFn);
};

// comparator for function createOriginOptions
const originOptionCompareFn = (optionA, optionB) => {

  if (!optionA.value.origin_city && optionB.value.origin_city) {
    return -1;
  }

  if (optionA.value.origin_city && !optionB.value.origin_city) {
    return 1;
  }

  const labelA = optionA.label;
  const labelB = optionB.label;

  return labelA.localeCompare(labelB);
}

// find origin option if exists
export const findOriginOption = (originOptions, originCountry, originCity) => {
  return originOptions.find(originOption => {
    return originOption.value.origin_country === originCountry && originOption.value.origin_city === originCity;
  })
}


// create destination options
export const createDestinationOptions = (countries) => {
  return countries.flatMap(country => {
    const countryOption =  {
      value: { destination_country: country.code, destination_city: null }, label: country.name
    };

    const cityOptions = country.cities.map(city => {
      return {
        value: { destination_country: country.code, destination_city: city.code }, label: `${city.name}, ${country.name}`
      };
    });

    return [countryOption, ...cityOptions];
  }).sort(destinationOptionCompareFn);
};

// comparator for function createDestinationOptions
const destinationOptionCompareFn = (optionA, optionB) => {

  if (!optionA.value.destination_city && optionB.value.destination_city) {
    return -1;
  }

  if (optionA.value.destination_city && !optionB.value.destination_city) {
    return 1;
  }

  const labelA = optionA.label;
  const labelB = optionB.label;

  return labelA.localeCompare(labelB);
}

// find destination option if exists
export const findDestinationOption = (destinationOptions, destinationCountry, destinationCity) => {
  return destinationOptions.find(destinationOption => {
    return destinationOption.value.destination_country === destinationCountry && destinationOption.value.destination_city === destinationCity;
  })
}
