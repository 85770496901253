import './AboutPage.css';
import Header from '../components/Header';
import { Link } from 'react-router-dom';

function AboutPage() {
  return (
    <div className="AboutPage"> 
      <Header/>

      <div className="about-page-content">
        <div className="about-page-title">Quiénes somos</div>

        <p>
          Somos una plataforma creada para conectar a personas que requieren el envío de paquetes con quienes se desplazan entre ciudades, ofreciendo una oportunidad de beneficio mutuo para ambas partes.
        </p>

        <p>
          Conscientes de los costos y la complejidad que implica el envío de artículos a largas distancias, nos hemos comprometido a transformar la manera en que se transportan los paquetes.
        </p>

        <p>
          Nuestra solución facilita el encuentro entre viajeros y remitentes de manera ágil y eficiente, promoviendo un enfoque colaborativo que favorece a todos los involucrados.
        </p>

        <Link to="/" className="about-page-content-link-to-sign-in">Ir a explorar</Link>
      </div>
    </div>
  );
}

export default AboutPage;


