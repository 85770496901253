const key = 'SESSION_TOKEN';

const getToken = () => {
  return localStorage.getItem(key);
}

const isLoggedIn = () => {
  const token = getToken();
  return token !== null && token !== undefined;
}

const logIn = (token) => {
  localStorage.setItem(key, token);
}

const logOut = () => {
  localStorage.removeItem(key);
}

export {
  getToken,
  isLoggedIn,
  logIn,
  logOut
};
