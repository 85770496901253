import './SignInPage.css';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { logIn } from '../lib/session';
import axios from 'axios';
import validator from 'validator';
import Input from '../components/Input';
import Header from '../components/Header';

function SignInPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState([]);

  const handleOnSignIn = (e) => {
    e.preventDefault();

    if (!validator.isEmail(email)) {
      setErrors(['El correo electrónico no tiene el formato correcto. Por favor, revísalo.']);
      return;
    }

    const params = { user: { email: email, password: password } };

    axios.post('/api/login', params)
      .then((response) => {
        const token = response.data.data.token;
        logIn(token);
        return navigate('/');
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          setErrors(['El correo electrónico o la contraseña son incorrectos. Por favor, intenta otra vez.']);
        } else {
          setErrors(['Ocurrió un error al iniciar sesión. Por favor, intenta otra vez.']);
        }
      });
  }

  const disableSubmitButton = email === '' || password === '';

  return (
    <div className="SignInPage">
      <Header/>
      <div className="sign-in-page-content">
        {/* <div className="sign-in-page-logo-container">
          <span className="sign-in-page-logo"></span>
        </div> */}

        <div className="sign-in-page-title">Inicio de sesión</div>

        {errors.length > 0 &&
          <div className="sign-in-page-errors-container">
            {errors.map((error, index) => <div className="sign-in-page-error" key={index}> {error}</div>)}
          </div>
        }

        <div className="sign-in-page-form">
            <form noValidate onSubmit={handleOnSignIn}>
              <div className="sign-in-page-input-container">
                <Input type="email" placeholder="Correo electrónico" value={email} onChange={(e) => setEmail(e.target.value)}/>
              </div>
              
              <div className="sign-in-page-input-container">
                <Input type="password" placeholder="Contraseña" value={password} onChange={(e) => setPassword(e.target.value)}/>
              </div>
              
              {/* <div onClick={() => alert('Missing interaction')} className="sign-in-page-forgot-password-link">Olvidé mi contraseña</div> */}

              <button className={`sign-in-page-submit-button ${disableSubmitButton ? 'sign-in-page-submit-button-disabled' : ''}`} type="submit" disabled={disableSubmitButton}>Iniciar sesión</button>
            </form>

            <div className="sign-in-page-sign-up-link-container">
              No tengo una cuenta. <Link to="/creacion-de-cuenta" className="sign-in-page-sign-up-link">Crear una</Link>
            </div>
        </div>
      </div>
    </div>
  );
}

export default SignInPage;
