import './TripList.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import TripItem from './TripItem';
import TripItemFacebook from './TripItemFacebook';


function TripList({ trips, fetchNextPage, hasMore }) {
  const loader = <div className="end-message">{'Cargando...'}</div>;

  const endMessage = (
    <div className="end-message">
      {trips.length === 0 ? 'No se han encontrado resultados' : 'No se han encontrado más resultados'}
    </div>);

  return (
    <div className="TripList">
      <InfiniteScroll
        dataLength={trips.length}
        next={fetchNextPage}
        hasMore={hasMore}
        scrollThreshold="800px"
        loader={loader}
        endMessage={endMessage}
        style={{overflow: 'visible'}}>

        {trips.map((trip) => {
          return (
            <div className="trip-list-item" key={trip.id}>
              {trip.source === 'facebook_group' ? <TripItemFacebook trip={trip}/> : <TripItem trip={trip}/>}
              <div className="trip-list-item-separator"/>
            </div>
          )}
        )}

      </InfiniteScroll>
    </div>
  );
}
   
export default TripList;
