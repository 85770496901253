import './TripItemFacebook.css';
import { Link } from 'react-router-dom';
import { ReactComponent as FacebookIcon } from '../icons/facebook.svg';
import { ReactComponent as AirplaneTakeOffIcon } from '../icons/airplane-take-off.svg';
import { useState } from 'react';
import { trackTripClickedEvent } from '../lib/analytics';

function TripItemFacebook({ trip }) {
  let {
    date, origin_country, origin_city, destination_country, destination_city, description
  } = trip;

  description = JSON.parse(description);
  const group = description.group;
  const author = description.author;
  const created_at = description.created_at;
  const url = description.url;
  description = description.content;
  const shortenedDescription = function() {
    const lines = description.split("\n");
    const length = description.length;

    if (lines.length > 6) {
      return lines.slice(0, 6).join("\n");
    }

    if (length > 300) {
      return description.slice(0, 300);
    }

    return description;
  }();

  const [showShortenedDescription, setShowShortenedDescription] = useState(description !== shortenedDescription);

  const origin = [origin_city, origin_country].filter((el => el)).join(', ');
  const destination = [destination_city, destination_country].filter((el => el)).join(', ');

  const timestampFormattingOptions = { year: 'numeric', month: 'short', day: 'numeric' };
  const creationDate = new Date(created_at).toLocaleDateString('es', timestampFormattingOptions);

  const dateFormattingOptions = { ...timestampFormattingOptions, timeZone: 'UTC' };
  const tripDate = new Date(date).toLocaleDateString('es', dateFormattingOptions);

  const handleOnClick = () => {
    trackTripClickedEvent();
  }

  return (
    <div className="TripItemFacebook">
        <div className="trip-item-facebook-header">
          <FacebookIcon className="facebook-icon"/>

          <div className="trip-item-facebook-header-content">
            <span className="trip-item-facebook-header-content-group-name">{group}</span>
            <span className="trip-item-facebook-header-content-author">Por {author}, el {creationDate}</span>
          </div>
        </div>

        <Link to={url} className="trip-item-facebook-name" target="_blank" rel="noopener noreferrer" onClick={handleOnClick}>
          {origin} → {destination}
        </Link>

        {date &&
          <div className="trip-item-facebook-date">
            <AirplaneTakeOffIcon className="airplane-take-off-icon"/>
            {tripDate}
          </div>
        }

        <div>
          {
            showShortenedDescription ?
              <TripItemFacebookShortenedDescription shortenedDescription={shortenedDescription} onClick={() => setShowShortenedDescription(false)}/>
              :
              <TripItemFacebookDescription description={description}/>
          }
        </div>
    </div>
  );
}
   
export default TripItemFacebook;

// The following components were created to overcome Google translation issues on mobile.
// Google translation modifies the DOM. In some cases it respects the directive translate="no", but not always.
// Once the DOM was modified, clicking "Ver más" resulted in an error of "The object can not be found here".

// The original code was using the ternary operator
// {
//   showShortenedDescription ?
//   <div>{shortenedDescription}... <div onClick={() => setShowShortenedDescription(false)}>Ver más</div></div>
//   :
//   <div>{description}</div>
// }

// We created the following components and the error went away.

function TripItemFacebookShortenedDescription({ shortenedDescription, onClick }) {
  return (
    <div className="trip-item-facebook-description" >
      {shortenedDescription}
      ...
      <div className="trip-item-facebook-show-more" onClick={onClick}>
        Ver más
      </div>
    </div>
  );
}

function TripItemFacebookDescription({ description, onClick }) {
  return (
    <div className="trip-item-facebook-description" >
      {description}
    </div>
  );
}
