import './AlertsPage.css';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { getToken } from '../lib/session';
import toast, { Toaster } from 'react-hot-toast';
import AlertList from '../components/AlertList';
import Header from '../components/Header';

function AlertsPage() {
  const [alerts, setAlerts] = useState(null);
  const [showHowAlertsWork, setShowHowAlertsWork] = useState(false);
  const [showHowToCreateAlerts, setShowHowToCreateAlerts] = useState(false);

  useEffect(() => {
    axios.get('/api/alerts', { headers: { 'Authorization': `Bearer ${getToken()}`} })
      .then((response) => {
        const alerts = response.data.data.alerts;
        setAlerts(alerts);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleOnDelete = (id) => {
    axios.delete(`/api/alerts/${id}`, { headers: { 'Authorization': `Bearer ${getToken()}`} })
      .then((response) => {
        toast.success('Alerta eliminada.', { id: 'deleted' });
        setAlerts(alerts.filter(alert => alert.id !== id));
      })
      .catch((error) => {
        toast.error('Ocurrió un error mientras se intentaba eliminar la alerta.', { id: 'deleted' });
        console.log(error);
      });
  };

  return (
    <div className="AlertsPage">  
      <Header/>

      <Toaster position="top-center" reverseOrder={false}/>

      <div className="alerts-page-content">
        <div className="alerts-page-title">Alertas</div>
        
        <p className="alerts-page-how-to" onClick={() => setShowHowAlertsWork(prev => !prev)}>¿Cómo funcionan las alertas?</p>
        {showHowAlertsWork && 
          <p className="alerts-page-how-to-answer">
            Recibirás un correo electrónico cuando se publiquen viajes que coincidan con el destino y origen de tus alertas.
          </p>
        }

        <p className="alerts-page-how-to" onClick={() => setShowHowToCreateAlerts(prev => !prev)}>¿Cómo crear alertas?</p>
        {showHowToCreateAlerts &&
          <>
            <p className="alerts-page-how-to-answer">
              Para crear alertas debes realizar una búsqueda en la sección <Link to="/" className="alerts-page-link-to-home">Explorar</Link>.
              Una vez realizada la búsqueda deberás hacer clic en la campanita.
            </p>

            <p className="alerts-page-how-to-answer">
              Solo es posible tener un máximo de <b>3</b> alertas simultáneamente.
            </p>
          </>
        }

        <div className="alerts-page-alerts-list">
          <AlertList alerts={alerts} onDelete={handleOnDelete}/>
        </div>
      </div>
    </div>
  );
}

export default AlertsPage;
