import './GenerateFacebookTripJsonPage.css'
import { useState } from "react";
import toast, { Toaster } from 'react-hot-toast';

function GenerateFacebookTripJsonPage() {
  const [group, setGroup] = useState('courier');
  const [date, setDate] = useState('');
  const [originCountry, setOriginCountry] = useState('estados_unidos');
  const [originCity, setOriginCity] = useState('');
  const [destinationCountry, setDestinationCountry] = useState('cuba');
  const [destinationCity, setDestinationCity] = useState('');
  const [facebookGroup, setFacebookGroup] = useState('');
  const [author, setAuthor] = useState('');
  const [content, setContent] = useState('');
  const [createAt, setCreatedAt] = useState('');
  const [url, setUrl] = useState('');

  const [tripJson, setTripJson] = useState(null);

  const handleOnClick = () => {
    const sourceId = url.split('/')[4] + '-' + url.split('/')[6];
    const formattedCreatedAt = new Date(createAt).toISOString().slice(0, 19) + 'Z';

    const trip = {
      source: 'facebook_group',
      source_id: sourceId,
      group: group,
      date: date === '' ? null : date,
      origin_country: originCountry,
      origin_city: originCity === '' ? null : originCity,
      destination_country: destinationCountry,
      destination_city: destinationCity === '' ? null : destinationCity,
      details: {
        group: facebookGroup,
        author: author,
        content: content,
        created_at: formattedCreatedAt,
        url: url
      }
    }

    setTripJson(JSON.stringify(trip, null, 2));
  }

  const fieldsToValidate = [group, originCountry, destinationCountry, facebookGroup, author, content, createAt, url];
  const disableGenerateButton = fieldsToValidate.filter((field) => field === '').length > 0;

  const handleCopy = () => {
    navigator.clipboard.writeText(tripJson);
    toast.success('JSON copiado', { id: 'copied' });
  }

  const handleClear = () => {
    setDate('');
    setOriginCity('');
    setDestinationCity('');
    setAuthor('');
    setCreatedAt('');
    setUrl('');
    setContent('');
    setTripJson(null);
  }

  return (
    <div className="GenerateFacebookTripJsonPage"> 

      <Toaster position="top-center" reverseOrder={false}/>

      <div className="generate-facebook-trip-json-page-title">Generate Facebook Trip Json</div>

      <div className="generate-facebook-trip-json-page-content">

        <div className="generate-facebook-trip-json-page-input-container" style={{float: 'left', marginRight: '16px'}}>
          <label htmlFor="group">Tipo de viaje</label>
          <select name="group" value={group} onChange={(e) => setGroup(e.target.value)} className="generate-facebook-trip-json-page-input">
            <option value="courier">Courier</option>
            <option value="sender">Sender</option>
          </select>
        </div>

        <div className="generate-facebook-trip-json-page-input-container">
          <label htmlFor="date">Fecha de viaje</label>
          <input name="date" type="date" className="generate-facebook-trip-json-page-input" value={date} onChange={(e) => setDate(e.target.value)}/>
        </div>

        <br style={{clear:'both'}}/>

        <div className="generate-facebook-trip-json-page-input-container" style={{float: 'left', marginRight: '16px'}}>
          <label htmlFor="origin-country">País origen</label>
          <input name="origin-country" type="text" className="generate-facebook-trip-json-page-input" value={originCountry} onChange={(e) => setOriginCountry(e.target.value)}/>
        </div>

        <div className="generate-facebook-trip-json-page-input-container">
          <label htmlFor="origin-city">Ciudad origen</label>
          <input name="origin-city" type="text" className="generate-facebook-trip-json-page-input" value={originCity} onChange={(e) => setOriginCity(e.target.value)}/>
        </div>

        <br style={{clear:'both'}}/>

        <div className="generate-facebook-trip-json-page-input-container" style={{float: 'left', marginRight: '16px'}}>
          <label htmlFor="destination-country">País destino</label>
          <input name="destination-country" type="text" className="generate-facebook-trip-json-page-input" value={destinationCountry} onChange={(e) => setDestinationCountry(e.target.value)}/>
        </div>

        <div className="generate-facebook-trip-json-page-input-container">
          <label htmlFor="destination-city">Ciudad destino</label>
          <input name="destination-city" type="text" className="generate-facebook-trip-json-page-input" value={destinationCity} onChange={(e) => setDestinationCity(e.target.value)}/>
        </div>

        <br style={{clear:'both'}}/>

        <div className="generate-facebook-trip-json-page-input-container">
          <label htmlFor="facebook-group">Facebook - Group</label>
          <input name="facebook-group" style={{width: '100%'}} type="text" className="generate-facebook-trip-json-page-input" value={facebookGroup} onChange={(e) => setFacebookGroup(e.target.value)}/>
        </div>

        <br style={{clear:'both'}}/>

        <div className="generate-facebook-trip-json-page-input-container">
          <label htmlFor="facebook-author">Facebook - Autor</label>
          <input name="facebook-author" style={{width: '100%'}} type="text" className="generate-facebook-trip-json-page-input" value={author} onChange={(e) => setAuthor(e.target.value)}/>
        </div>

        <br style={{clear:'both'}}/>

        <div className="generate-facebook-trip-json-page-input-container">
          <label htmlFor="creation-date">Facebook - Fecha de creación</label>
          <input name="creation-date" type="datetime-local" className="generate-facebook-trip-json-page-input" value={createAt} onChange={(e) => setCreatedAt(e.target.value)}/>
        </div>

        <br style={{clear:'both'}}/>

        <div className="generate-facebook-trip-json-page-input-container">
          <label htmlFor="url">Facebook - URL</label>
          <input name="url" type="text" style={{width: '100%'}} className="generate-facebook-trip-json-page-input" value={url} onChange={(e) => setUrl(e.target.value)}/>
        </div>

        <br style={{clear:'both'}}/>

        <div className="generate-facebook-trip-json-page-input-container">
          <label htmlFor="facebook-content">Facebook - Contenido</label>
          <textarea style={{padding: '16px', height: '200px', width: '100%'}} name="facebook-content" rows={10} className="generate-facebook-trip-json-page-input" value={content} onChange={(e) => setContent(e.target.value)}/>
        </div>

        <br style={{clear:'both'}}/>

        <div className="generate-facebook-trip-json-page-generate-button-container">
          <button className={`generate-facebook-trip-json-page-generate-button ${disableGenerateButton ? ' generate-facebook-trip-json-page-generate-button-disabled' : ''}`} onClick={handleOnClick} disabled={disableGenerateButton}>Generate Json</button>
        </div>

        {tripJson &&
          <div>
            <hr style={{margin: '24px 0'}}></hr>

            <div style={{display: 'flex', justifyContent: 'space-between', gap: '16px'}}>
              <button style={{width: 'unset', flexGrow: '1'}}
                className="generate-facebook-trip-json-page-generate-button"
                onClick={handleCopy}>
                  Copiar
              </button>

              <button style={{width: 'unset', flexGrow: '1', backgroundColor: '#d75151'}}
                className="generate-facebook-trip-json-page-generate-button"
                onClick={handleClear}>
                  Limpiar
              </button>
            </div>

            <pre className="generate-facebook-trip-json-page-trip-json">
              {tripJson}
            </pre>
          </div>
        }
      </div>
    </div>
  );
}


export default GenerateFacebookTripJsonPage;

