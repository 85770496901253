import './Menu.css';
import { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ReactComponent as CloseIcon } from '../icons/close.svg';
import { isLoggedIn, logOut } from '../lib/session';
import { disableScrolling, enableScrolling } from '../lib/scrolling';

function Menu({onClose}) {
  const isLogged = isLoggedIn();

  const { pathname } = useLocation();

  const handleSameRoute = (route) => {
    if (route === pathname) {
      onClose();
    }
  }

  useEffect(() => {
    disableScrolling();
    return enableScrolling;
  }, []);

  return (
    <div className="Menu">
      <div className="menu-container">
        <div className="menu-close">
          <CloseIcon onClick={() => onClose()} className="close-icon"/>
        </div>

        <div className="menu-content">
          {!isLogged && <NavLink to="/inicio-de-sesion" onClick={() => handleSameRoute('/inicio-de-sesion')} className="menu-item">Iniciar sesión</NavLink>}

          <NavLink to="/" onClick={() => handleSameRoute('/')} className="menu-item">Explorar</NavLink>

          {isLogged && <NavLink to="/mis-viajes" onClick={() => handleSameRoute('/mis-viajes')} className="menu-item">Mis viajes</NavLink>}

          {/* {isLogged && <NavLink to="/perfil" onClick={() => handleSameRoute('/perfil')} className="menu-item">Perfil</NavLink>} */}

          {isLogged &&  <NavLink to="/alertas" onClick={() => handleSameRoute('/alertas')} className="menu-item">Alertas</NavLink>}

          <NavLink to="/quienes-somos" onClick={() => handleSameRoute('/quienes-somos')} className="menu-item">Quiénes somos</NavLink>

          {/* <NavLink to="/faqs" onClick={() => handleSameRoute('/faqs')} className="menu-item">FAQs</NavLink> */}

          {isLogged && <NavLink to="/inicio-de-sesion" onClick={() => logOut()} className="menu-item">Cerrar sesión</NavLink>}
        </div>
      </div>
    </div>
  );
}
   
export default Menu;
