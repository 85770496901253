import './MyTripsPage.css';
import Header from '../components/Header';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getToken } from '../lib/session';
import TripItem from '../components/TripItem';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

function MyTripsPage() {
  const navigate = useNavigate();
  const [trips, setTrips] = useState(null);

  useEffect(() => {
    axios.get('/api/trips/my_trips', { headers: { 'Authorization': `Bearer ${getToken()}`} })
      .then((response) => {
        const trips = response.data.data.trips;
        setTrips(trips);
      })
      .catch((error) => console.log(error));
  }, []);

  const emptyState = <div className="my-trips-empty-state">
    Aún no has creado tu primer viaje

    <div style={{marginTop: '16px', textAlign: 'center', flex: '0 0 auto'}}>
      <button className="my-trips-page-create-trip-button" onClick={() => navigate('/crear-viaje')}>
        Crear viaje
      </button>
    </div>
  </div>

  const onDeleteTrip = (tripId) => {
    axios.delete(`/api/trips/${tripId}`, { headers: { 'Authorization': `Bearer ${getToken()}`} })
      .then((response) => {
        toast.success('Viaje eliminado.', { id: 'deleted' });
        setTrips(trips => trips.filter(trip => trip.id !== tripId));
      })
      .catch((error) => {
        toast.error('Ocurrió un error mientras se intentaba eliminar el viaje.', { id: 'deleted' });
        console.log(error);
      });
  }

  return (
    <div className="MyTripsPage"> 
      <Header/>

      <Toaster position="top-center" reverseOrder={false}/>

      <div className="my-trips-page-content">
        <div className="my-trips-page-title">
          Mis viajes

          {trips !== null && trips.length > 0 &&
            <button className="my-trips-page-create-trip-button" onClick={() => navigate('/crear-viaje')}>Crear viaje</button>
          }
        </div>

        {trips !== null && trips.length === 0 && emptyState}

        {trips !== null && trips.length > 0 &&
            <div className="my-trips-page-trips">

              {trips.map((trip) => {
                return (
                  <div className="trip-list-item" key={trip.id}>
                    <TripItem trip={trip} onDelete={onDeleteTrip}/>
                    <div className="trip-list-item-separator"/>
                  </div>
                )}
              )}
            </div>  
        }
    
      </div>
    </div>
  );
}

export default MyTripsPage;
