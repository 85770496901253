import './VerifyEmailPage.css';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Header from '../components/Header';
import { useSearchParams } from "react-router-dom";

function VerifyEmailPage() {
  const [verificationResult, setVerificationResult] = useState('pending');
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const params = { token: searchParams.get('token') };

    axios.get('/api/users/verify_email', { params: params })
      .then((response) => {
        setVerificationResult('success');
      })
      .catch((error) => {
        console.log(error);
        setVerificationResult('failed');
      });
  }, []);

  if (verificationResult === 'pending') {
    return null;
  }
  
  return (
    <div className="VerifyEmailPage">
      <Header/>

      <div className="verify-email-page-content">
        <div className="about-page-title">Verificación de correo electrónico</div>

        {verificationResult === 'success' && <VerifyEmailSuccessful/>}
        {verificationResult === 'failed' && <VerifyEmailFailed/>}
      </div>
    </div>
  );
}

export default VerifyEmailPage;

function VerifyEmailSuccessful() {
  return (
    <div className="VerifyEmailSuccessful">
      <p>Tu correo electrónico ha sido verificado. Ya puedes iniciar sesión.</p>
      <Link to="/inicio-de-sesion" className="verify-email-page-link-to-sign-in">Ir a iniciar sesión</Link>
    </div>
  )
}

function VerifyEmailFailed() {
  return (
    <div className="VerifyEmailFailed">
      <p>Ocurrió un error mientras se verificaba tu dirección de correo electrónico.</p>
    </div>
  )
}
